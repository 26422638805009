import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/template/Layout'
import Cal from '@material-ui/icons/Today'
import { Grid, Container } from '@material-ui/core'

export default function News({ data }) {
  const post = data.markdownRemark
  return (
    <Layout title={`Actualité: ${post.frontmatter.title}`} description={post.frontmatter.description} type="Actualité" image="https://chateaubriant.jcef.asso.fr/static/b6563a053b3907c2c82b4c56278a3648/2a4de/logo.png">
      <Container>
        <Grid container>
          <Grid item><Cal/></Grid>
          <Grid item><time>{post.frontmatter.date}</time></Grid>
        </Grid>
        <h2>{post.frontmatter.description}</h2>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </Container>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        date(formatString: "dddd D MMMM YYYY", locale:"fr")
      }
      excerpt
    }
  }
`